import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Arrow from "../../static/images/Arrow.svg"
import InstantoWhite from "../../static/images/Instanto_logo_white.svg"
import "../assets/components/bootstrap/css/bootstrap.min.css"
import "../assets/scss/theme.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

const App = () => {
  const query = useStaticQuery(graphql`
    query {
      pci_comp: file(relativePath: { eq: "pci_dss_small_compressed.png" }) {
        childImageSharp {
          fixed(width: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // const query = useStaticQuery(graphql`
  //   query {
  //     globe: file(relativePath: { eq: "globe_cropped_3.jpg" }) {
  //       publicURL
  //       childImageSharp {
  //         fluid(maxWidth: 1920) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     globeMobileSmall: file(
  //       relativePath: { eq: "globe_cropped_mobile_small_scaled.jpg" }
  //     ) {
  //       publicURL
  //       childImageSharp {
  //         fluid(maxWidth: 375) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     globeMobile: file(relativePath: { eq: "globe_cropped_mobile.jpg" }) {
  //       publicURL
  //       childImageSharp {
  //         fluid(maxWidth: 576) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  const words = [
    "YOU",
    "THEM",
    "ME",
    "USA",
    "EU",
    "EMPLOYEES",
    "FREELANCERS",
    "SUPPLIERS",
    "AFRICA",
  ]

  const [currentWordIndex, setCurrentWordIndex] = React.useState(0)
  const [wordTimeout, setWordTimeout] = React.useState(-1) // wrapper used for lazy initialization, then empty function returned
  const slideTimeout = 1_500
  const nextWord = () => {
    clearTimeout(wordTimeout)
    setCurrentWordIndex(f => (f + 1) % words.length)
    setWordTimeout(() => setTimeout(nextWord, slideTimeout))
  }

  // const previousFeaturedPost = () => {
  //   clearTimeout(wordTimeout)
  //   setFeaturedIndex(f => (f + featuredEdges.length - 1) % featuredEdges.length)
  //   setWordTimeout(() => setTimeout(nextFeaturedPost, slideTimeout))
  // }

  React.useEffect(() => {
    setWordTimeout(() => setTimeout(nextWord, slideTimeout))
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <header className="hero p-0 header-container" id="top">
        <div className="position-relative">
          <div className="position-relative">
            {/* <Img
              fluid={[
                query.globeMobileSmall.childImageSharp.fluid,
                {
                  ...query.globe.childImageSharp.fluid,
                  media: `(min-width: 577px)`,
                },
                {
                  ...query.globeMobile.childImageSharp.fluid,
                  media: `(min-width: 376px)`,
                },
              ]}
              className="w-100"
              style={{ minHeight: 850 }}
            /> */}

            <div
              className="w-100 gatsby-image-wrapper"
              style={{
                position: "relative",
                overflow: "hidden",
                minHeight: 850,
              }}
            >
              <div
                aria-hidden="true"
                style={{ width: "100%", paddingBottom: "103.75%" }}
              ></div>
              <picture>
                <source
                  media="(min-width: 577px)"
                  srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAeJstG2NGUVNAMK//8QAGxAAAwACAwAAAAAAAAAAAAAAAAEDAhEQEkL/2gAIAQEAAQUC0YwbTmS6J1s8jZ54/8QAGREAAQUAAAAAAAAAAAAAAAAAAQAQERIx/9oACAEDAQE/AbE4oL//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAREv/aAAgBAgEBPwHKRan/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRICEy/9oACAEBAAY/Ai3pHaLll//EABsQAQACAgMAAAAAAAAAAAAAAAEAERAhMVGR/9oACAEBAAE/ISPexBuKpFhuP86OouLQM//aAAwDAQACAAMAAAAQSB+B/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEGH/2gAIAQMBAT8QaQ1Ir//EABgRAAMBAQAAAAAAAAAAAAAAAAABERBh/9oACAECAQE/EIaziN5//8QAHRABAAMAAgMBAAAAAAAAAAAAAQARIUGREDFRgf/aAAgBAQABPxB1oLmb+B5fktawzbjIQsD1KmUyBZHO33KQPer9i+P/2Q=="
                />
                <source
                  media="(min-width: 376px)"
                  srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAYW2VFKUVDWUY5VS7Fhp/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAECAxEQEiH/2gAIAQEAAQUCMOjFDSFKgnatqkoOy1yNH4uP/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECAxES/9oACAEDAQE/AXPDtirMP//EABgRAQADAQAAAAAAAAAAAAAAAAABAhIR/9oACAECAQE/AcMJu6//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQECICH/2gAIAQEABj8Cw7nBzr//xAAcEAADAQACAwAAAAAAAAAAAAAAAREhEDFBUWH/2gAIAQEAAT8hFQicc73uFKEkZgaWnnfo2pnXsvH/2gAMAwEAAgADAAAAEJ8Ozv/EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhYf/aAAgBAwEBPxBOhyEW4f/EABgRAAMBAQAAAAAAAAAAAAAAAAABEUEQ/9oACAECAQE/EFpkGc4//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUZH/2gAIAQEAAT8QDI6wiC0jWEOthRsrhNwoyUmNUPGMcorT1cV4fI6PotYqpc//2Q=="
                />
                <source srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAsABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgUE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHCmWMrO8RTqqisOkxgqyYXO1igd//EABwQAAIDAQADAAAAAAAAAAAAAAECAAMREhAgMf/aAAgBAQABBQL0CxaGIVNlVIUNYu0FQbLoWnWL0Zsf74//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARES/9oACAEDAQE/AbaXMsMwp//EABgRAQADAQAAAAAAAAAAAAAAAAABERIC/9oACAECAQE/AaZU1CepW//EABwQAAEDBQAAAAAAAAAAAAAAAAEAEBECICIwMf/aAAgBAQAGPwK3jTU2QRjR/8QAGxABAAMBAAMAAAAAAAAAAAAAAQAQEUEhcYH/2gAIAQEAAT8hrJkZZohvygF78hs7vuaDQCMvBmjFodYsZtF53//aAAwDAQACAAMAAAAQg82DKN//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QSWZZltgLF//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QTsscYiGDRZ//xAAeEAEBAQEAAQUBAAAAAAAAAAABEQAhURAxQWFxkf/aAAgBAQABPxCabnVuSLhpZ+TjLHNFYVRFh5yCoCCruoDKZhh5B4MtOH663JH+YSuDLvsZH6HXvp//2Q==" />
                <img
                  aria-hidden="true"
                  src="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAeJstG2NGUVNAMK//8QAGxAAAwACAwAAAAAAAAAAAAAAAAEDAhEQEkL/2gAIAQEAAQUC0YwbTmS6J1s8jZ54/8QAGREAAQUAAAAAAAAAAAAAAAAAAQAQERIx/9oACAEDAQE/AbE4oL//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAREv/aAAgBAgEBPwHKRan/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRICEy/9oACAEBAAY/Ai3pHaLll//EABsQAQACAgMAAAAAAAAAAAAAAAEAERAhMVGR/9oACAEBAAE/ISPexBuKpFhuP86OouLQM//aAAwDAQACAAMAAAAQSB+B/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEGH/2gAIAQMBAT8QaQ1Ir//EABgRAAMBAQAAAAAAAAAAAAAAAAABERBh/9oACAECAQE/EIaziN5//8QAHRABAAMAAgMBAAAAAAAAAAAAAQARIUGREDFRgf/aAAgBAQABPxB1oLmb+B5fktawzbjIQsD1KmUyBZHO33KQPer9i+P/2Q=="
                  alt=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                    opacity: 0,
                    transitionDelay: "500ms",
                  }}
                />
              </picture>
              <picture>
                <source
                  media="(min-width: 577px)"
                  srcSet="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/9104c/globe_cropped_3.jpg 480w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/a6352/globe_cropped_3.jpg 960w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg 1920w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/92311/globe_cropped_3.jpg 1930w"
                  sizes="(max-width: 1920px) 100vw, 1920px"
                />
                <source
                  media="(min-width: 376px)"
                  srcSet="/images/ed6601a550dafa5987ee1b388798938b/24010/globe_cropped_mobile.jpg 144w,
/images/ed6601a550dafa5987ee1b388798938b/37e8d/globe_cropped_mobile.jpg 288w,
/images/ed6601a550dafa5987ee1b388798938b/6aab9/globe_cropped_mobile.jpg 576w,
/images/ed6601a550dafa5987ee1b388798938b/c8d5d/globe_cropped_mobile.jpg 864w,
/images/ed6601a550dafa5987ee1b388798938b/3822b/globe_cropped_mobile.jpg 1152w,
/images/ed6601a550dafa5987ee1b388798938b/67aef/globe_cropped_mobile.jpg 1372w"
                  sizes="(max-width: 576px) 100vw, 576px"
                />
                <source
                  srcSet="/images/f60dd9ae012731c214462442a7e4a8fe/47d7b/globe_cropped_mobile_small_scaled.jpg 94w,
/images/f60dd9ae012731c214462442a7e4a8fe/2afb2/globe_cropped_mobile_small_scaled.jpg 188w,
/images/f60dd9ae012731c214462442a7e4a8fe/01f8d/globe_cropped_mobile_small_scaled.jpg 375w,
/images/f60dd9ae012731c214462442a7e4a8fe/2244e/globe_cropped_mobile_small_scaled.jpg 400w"
                  sizes="(max-width: 375px) 100vw, 375px"
                />
                <img
                  sizes="(max-width: 1920px) 100vw, 1920px"
                  srcSet="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/9104c/globe_cropped_3.jpg 480w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/a6352/globe_cropped_3.jpg 960w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg 1920w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/92311/globe_cropped_3.jpg 1930w"
                  src="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg"
                  alt=""
                  loading="lazy"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                    opacity: 1,
                    transition: "opacity 500ms ease 0s",
                  }}
                />
              </picture>
              <noscript>
                <picture>
                  <source
                    media="(min-width: 577px)"
                    srcSet="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/9104c/globe_cropped_3.jpg 480w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/a6352/globe_cropped_3.jpg 960w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg 1920w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/92311/globe_cropped_3.jpg 1930w"
                    sizes="(max-width: 1920px) 100vw, 1920px"
                  />
                  <source
                    media="(min-width: 376px)"
                    srcSet="/images/ed6601a550dafa5987ee1b388798938b/24010/globe_cropped_mobile.jpg 144w,
/images/ed6601a550dafa5987ee1b388798938b/37e8d/globe_cropped_mobile.jpg 288w,
/images/ed6601a550dafa5987ee1b388798938b/6aab9/globe_cropped_mobile.jpg 576w,
/images/ed6601a550dafa5987ee1b388798938b/c8d5d/globe_cropped_mobile.jpg 864w,
/images/ed6601a550dafa5987ee1b388798938b/3822b/globe_cropped_mobile.jpg 1152w,
/images/ed6601a550dafa5987ee1b388798938b/67aef/globe_cropped_mobile.jpg 1372w"
                    sizes="(max-width: 576px) 100vw, 576px"
                  />
                  <source
                    srcSet="/images/f60dd9ae012731c214462442a7e4a8fe/47d7b/globe_cropped_mobile_small_scaled.jpg 94w,
/images/f60dd9ae012731c214462442a7e4a8fe/2afb2/globe_cropped_mobile_small_scaled.jpg 188w,
/images/f60dd9ae012731c214462442a7e4a8fe/01f8d/globe_cropped_mobile_small_scaled.jpg 375w,
/images/f60dd9ae012731c214462442a7e4a8fe/2244e/globe_cropped_mobile_small_scaled.jpg 400w"
                    sizes="(max-width: 375px) 100vw, 375px"
                  />
                  <img
                    loading="lazy"
                    sizes="(max-width: 1920px) 100vw, 1920px"
                    srcSet="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/9104c/globe_cropped_3.jpg 480w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/a6352/globe_cropped_3.jpg 960w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg 1920w,
/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/92311/globe_cropped_3.jpg 1930w"
                    src="/images/08dbe8a1bdf6ac6d0c8560b99ddeb4ab/d8255/globe_cropped_3.jpg"
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      opacity: 1,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                  />
                </picture>
              </noscript>
            </div>
          </div>
          <div className="container position-absolute header-content p-top p-right p-left p-bottom px-0">
            <div className="h-100 d-flex justify-content-between flex-column">
              <div className="header-title mt-md-12 text-center text-lg-left">
                <h1 className="mb-4 h-hero px-2 px-md-0">
                  Cross-border payments
                  <br />
                  at internet speed.
                </h1>
                <div className="instanto-animation mt-lg-0">
                  <InstantoWhite className="instanto-animation-logo" />
                  <span className="h1 instanto-animation-text m-0 fw-black">
                    &nbsp;
                    {`${
                      currentWordIndex === -1
                        ? ""
                        : "[" + words[currentWordIndex] + "]"
                    }`}
                  </span>
                </div>
              </div>
              <section id="about" className="px-0 py-0 m-0">
                <div className="mb-10 mb-md-12 pt-md-12 text-center px-md-8">
                  <h2 className="px-1">
                    Everything on the internet works in real time. <br />
                    So why shouldn’t payments?
                  </h2>
                  <p className="fw-bold pt-3 text-center px-1">
                    Instanto exists to solve a long-lasting challenge in
                    finance; enabling businesses to make cross-border payments
                    at internet speed.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </header>

      <main className="main-container">
        <section id="product" className="section p-0 pb-4 py-lg-0">
          <div className="container">
            <div className="row px-md-8">
              <div className="col-12 text-center">
                <h2 className="mt-6 mb-4">Why we do it?</h2>
              </div>
              <div className="col-12 text-center">
                <p className="fw-bosld pt-md-3">
                  Traditional banking technology and trends in international
                  regulations and compliance have multiple challenges that make
                  life difficult for today’s global businesses. The majority of
                  these challenges derives from old legacy payment systems.
                </p>
                <p className="fw-bosld pt-3">
                  Instanto helps its clients create secure, repeatable and
                  instantaneous international payment rails, through
                  state-of-the-art technology, collaboration with
                  forward-thinking digital and licensed actors, and through
                  innovative disruption of how money is traditionally sent.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-4 text-center px-3 py-3">
                <img src={"images/Icon1.svg"} alt="" />
                <div>
                  The globalization of trade and services, combined with the
                  growth of digital business, increasingly removes the physical
                  element of cross-border operations. Companies that were born
                  online can operate across borders as long as they can transact
                  with ease. Large numbers of freelancers and asset owners need
                  to be paid all over the world, and they expect instant
                  payments.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-3 py-3">
                <img src={"images/Icon2.svg"} alt="" />
                <div>
                  Instanto has taken the latest innovations in this field one
                  step further, to provide digital businesses with new, safe and
                  instant payment rails. Our unique expertise, market knowledge
                  and robust compliance within a transparent supply chain means
                  your business no longer has to depend on traditional payment
                  services.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-3 py-3">
                <img src={"images/Icon3.svg"} alt="" />
                <div>
                  Instanto was founded by experienced financial markets experts
                  and provides international trade finance with a purpose-built
                  platform, enabling seamless, lightning-fast and highly
                  accessible transactions through our payment rail solutions.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pci-compliance" className="section p-0 pb-4 py-lg-0">
          <div className="container">
            <div className="row px-md-8">
              <div className="col-12 text-center">
                <h3 className="mt-10 mx-1">
                  Instant Payment Technology Sweden AB is a certified Level 1
                  Service Provider for PCI DSS for Internet/e-commerce &amp;
                  Merchant Services
                </h3>
              </div>
              <div className="col-12 text-center my-2 hov">
                <Link to="/compliance" className="text-white">
                  <Arrow style={{ width: 24 }} />
                  <span className="ml-1">Certificate</span>
                </Link>
              </div>
              <div className="col-12 text-center my-2 hov">
                <Img fixed={query.pci_comp.childImageSharp.fixed} />
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="section z-1 p-0 pb-8 custom-footer">
          <div className="container">
            <div className="row pt-lg-5 pb-lg-7 pb-6">
              <div className="col-12 bg-white" style={{ height: 2 }}></div>
            </div>
            <div className="row">
              <div className="col-12 text-center mb-3">
                <img
                  src="/images/Instanto_logo_NEG.svg"
                  alt=""
                  style={{ height: 24 }}
                />
              </div>
              <div className="col-12 text-center">
                <div className={`fs-md fw-bold`}>
                  Want to know more about Instanto? Get in touch with us on{" "}
                  <a
                    className="fw-bold text-white"
                    href="mailto:connect@instanto.eu"
                  >
                    connect@instanto.eu
                  </a>
                  .
                </div>

                <div className="fs-sm pt-1 mb-8">
                  &copy; Copyright 2020 Instant Payment Technology Sweden AB.
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default App
